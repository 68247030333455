:root {
  /* semantic variables */
  --colour-primary-800: #063b73;
  --colour-primary-600: #063b73;
  --colour-primary-400: #3c6691;

  --colour-secondary: #016f70;

  --colour-tertiary-200: #1785ec;
  --colour-tertiary-400: #0463bc;

  --colour-award: #ffbb1c;

  --colour-warning: #ff9f43;

  --colour-error: #ee5253;

  --colour-paper-015: #fffcf7;
  --colour-paper-025: #fdefd6;

  --colour-success-025: #e2f5f0;
  --colour-success-050: #a6e0d1;
  --colour-success-100: #67cab1;
  --colour-success-400: #0a7157;

  --colour-neutral-025: #f6f6f7;
  --colour-neutral-050: #d4d4d6;
  --colour-neutral-100: #b8b8bb;
  --colour-neutral-200: #838388;
  --colour-neutral-400: #636369;
  --colour-neutral-600: #46464d;

  --colour-background-010: #fbfcfd;
  --colour-background-015: #f8f9fb;
  --colour-background-025: #f4f7f9;
  --colour-background-050: #cad6e1;

  --accent-green-200: #1e9575;

  --colour-white: #fff;
  --colour-black: #000;

  /* contextual variables */
  --page-background-light: var(--colour-white);
  --page-background-dark: var(--colour-background-025);

  --text-main-on-dark: var(--colour-white);
  --text-main-on-light: var(--colour-black);
  --text-on-secondary: var(--colour-white);
  --text-accent-on-dark: var(--colour-white);
  --text-accent-on-light: var(--colour-secondary);
  --border-radius-cta: 32px;
  --border-radius-inputs: 8px;
  --border-radius-card-medium: 8px;
  --border-radius-card-large: 16px;

  /* fonts: START */
  --base-font-size: 16px;
  --base-font-family: 'Open Sans', Lato, Helvetica, Montserrat;

  --fancy-font-family: 'Plus Jakarta Sans', Lato, Helvetica, Montserrat;
  /* fonts: END */

  --border-radius-medium: 16px;
  --border-radius-small: 8px;

  /* elements/CTA: START */
  --cta-primary-hover-box-shadow: 0 6px 12px -8px var(--colour-black),
    0 5px 30px -5px rgba(1, 111, 112, 0.6);
  --cta-primary-hover-box-shadow-dark: 0 4px 16px rgba(0, 0, 0, 0.05);
  --cta-primary-box-shadow: 0 6px 12px -8px var(--colour-black),
    0 5px 30px -5px var(--colour-secondary);
  --cta-primary-box-shadow-with-opacity: 0 6px 18px -15px var(--colour-primary-600),
    0 5px 30px -5px rgba(1, 111, 112, 0.6);
  /* elements/CTA: END */

  --quiz-step-transition-background: rgba(251, 252, 253, 0.9);
  /*background010 colour with 0.9 opacity*/

  /* templates/PageFooter: START */
  --page-footer-background-top: var(--colour-primary-400);
  --page-footer-background-mid: var(--colour-primary-600);
  --page-footer-background-bottom: var(--colour-primary-800);

  --page-footer-text: var(--colour-white);
  --page-footer-text-link: var(--colour-secondary);
  --page-footer-text-link-alt: var(--colour-white);
  /* templates/PageFooter: END */

  --hero-background-overlay: linear-gradient(
    rgba(6, 59, 115, 0.85),
    rgba(6, 59, 115, 0.85)
  );
  --hero-background-overlay-mobile: linear-gradient(
    rgba(6, 59, 115, 0.85) 40%,
    rgba(6, 59, 115, 0.85) 55%
  );
  --hero-background-overlay-bar-tiles: linear-gradient(
    rgba(6, 59, 115, 0.4),
    rgba(6, 59, 115, 0.4)
  );

  --search-result-background: var(--colour-background-010);
  --radio-checked-color: var(--colour-secondary);

  --guidebook-activation-modal-shadow: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(1, 111, 112, 0.6)
  );
}

.CTA-block-primary.CTA-block-primary {
  color: var(--colour-white);
}

[class^='CTA-block'][class^='CTA-block'] {
  border-radius: var(--border-radius-cta);
}
