.PageHeader-CTA-block.PageHeader-CTA-block.PageHeader-CTA-block {
  background-color: var(--colour-secondary);
  border-color: var(--colour-secondary);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  color: var(--colour-white) !important;
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .PageHeader-CTA-block.PageHeader-CTA-block.PageHeader-CTA-block:hover {
    color: var(--text-main-on-dark);
    transform: translateY(-1px);
  }
}

.PageHeader-wrapper.PageHeader-wrapper.PageHeader-wrapper,
.PageHeader-wrapper.PageHeader-wrapper.PageHeader-wrapper--with-rating {
  background-color: var(--colour-white);
  color: var(--text-main-on-light);
  box-shadow: var(--cta-primary-hover-box-shadow-dark);
}

.PageHeader-button.PageHeader-button {
  color: var(--text-main-on-light);
}

.PageHeader-additional-button.PageHeader-additional-button {
  margin-left: var(--space-010);
}

.PageHeader-button use,
.PageHeader-button path {
  stroke: var(--text-main-on-light);
}

.PageHeader-menu-fullwidth-content *,
.PageHeader-menu * {
  color: var(--text-main-on-light);
}

.PageHeader-wrapper--with-rating .HLP-hero-rating-star use {
  fill: var(--colour-secondary);
}

.PageHeader-wrapper--with-rating .HLP-hero-rating-star--cut use {
  fill: var(--colour-white);
}

.HLP-hero-rating-star.HLP-hero-rating-star--empty use {
  fill: var(--colour-neutral-025);
}

@media (max-width: 339px) {
  .PageHeader-logo-link.PageHeader-logo-link--compact svg {
    max-width: 140px;
  }
}

@media (max-width: 385px) {
  .PageHeader-logo-link.PageHeader-logo-link--compact svg {
    max-width: 160px;
  }
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .PageHeader-nav-link:hover.PageHeader-nav-link:hover,
  .PageHeader-button:hover.PageHeader-button:hover {
    text-decoration: none;
    background-color: var(--colour-neutral-025);
    border-radius: var(--border-radius-cta);
  }
}

.PageHeader-nav-link:focus-visible,
.PageHeader-CTA-block:focus-visible,
.PageHeader-button:focus-visible {
  background: none;
  outline: 2px solid var(--colour-primary-600);
}

.is-scrolled .PageHeader-CTA-block:focus-visible {
  outline: 2px solid var(--colour-primary-600);
}
