.LinkDownload-block.LinkDownload-block {
  background: var(--colour-background-025);
}

.FooterCTA-heading {
  color: var(--text-main-on-dark);
}

.cms-article-page {
  background: var(--colour-background-025);
}

.cms-article-page .HLP-hero-image-wrapper:after {
  display: none;
}

@media (max-width: 767px) {
  .HLP-hero-heading {
    height: 75vw;
  }
}

@media (min-width: 768px) {
  .HLP-hero-heading h1,
  .HLP-hero-heading span {
    color: var(--text-main-on-light);
  }
  .HLP-hero-heading svg * {
    fill: var(--colour-primary-600);
  }
}
