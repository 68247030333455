.PageFooter-top {
  max-width: 660px;
  margin: auto;
}

.PageFooter-nav-links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px 32px;
  max-width: 660px;
}

.PageFooter-nav-links a {
  font-size: 14px;
  font-weight: 700;
  color: var(--colour-black);
  text-decoration: none;
  white-space: nowrap;
}

.PageFooter-copyright a {
  text-decoration: none;
  color: inherit;
}

.PageFooter-nav-links a:hover,
.PageFooter-copyright a:hover {
  text-decoration: underline;
}

.PageFooter-social-links {
  margin-top: 32px;
  display: flex;
  justify-content: center;
}

.PageFooter-resources {
  display: flex;
  justify-content: center;
  gap: 32px;
}

.PageFooter-brands {
  width: 100%;
  border-top: 1px solid var(--colour-neutral-050);
  padding: 64px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PageFooter-brands__main {
  width: 138px;
  height: 22px;
}

.PageFooter-brands__minors {
  display: flex;
  gap: 32px;
  margin: 32px 0;
  flex-wrap: wrap;
  justify-content: center;
}

.PageFooter-brands__minor {
  width: 120px;
  height: 42px;
  padding: 10px;
  background: var(--colour-neutral-025);
  object-fit: contain;
}

.PageFooter-sustainability {
  display: flex;
  align-items: center;
  gap: 32px;
}

.PageFooter-copyright {
  margin: 0 auto 24px auto;
  padding: 0 8px;
  text-align: center;
}

@media (max-width: 767px) {
  .PageFooter-nav-links {
    gap: 32px;
  }

  .PageFooter-brands {
    padding: 32px 0;
  }

  .PageFooter-sustainability {
    flex-direction: column;
    gap: 16px;
  }

  .PageFooter-copyright {
    margin-bottom: 16px;
  }
}
