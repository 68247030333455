@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('https://cdn.bauen.de/s/webfonts/plus-jakarta-sans/plus-jakarta-sans-bold.woff2')
      format('woff2'),
    url('https://cdn.bauen.de/s/webfonts/plus-jakarta-sans/plus-jakarta-sans-bold.woff')
      format('woff');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Open Sans';
  src: url('https://cdn.bauen.de/s/webfonts/open-sans/OpenSans-Regular.woff2')
      format('woff2'),
    url('https://cdn.bauen.de/s/webfonts/open-sans/OpenSans-Regular.woff')
      format('woff');
  font-style: normal;
  font-weight: 400;
}
