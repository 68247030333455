.QuizApp-success {
  text-align: center;
}

.QuizApp.QuizApp {
  background: var(--colour-background-010);
}

.QuizApp-transition {
  background: var(--colour-background-010);
}

.QuizApp-checkout-image--text {
  color: var(--text-accent-on-light);
}
